import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivationEnd } from "@angular/router";
import { BehaviorSubject, Subscription, interval } from "rxjs";
import { filter } from "rxjs/operators";

import { Constants } from "src/app/constants/constants";
import { ModalService } from "src/app/components/shared/modals/modal.service";

import { TranscodingProfilesService } from "../transcoding-profiles.service";
import { TranscodingProfile } from "../transcoding-profile";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../services/title.service";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { KeyMap } from "src/app/models/shared";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";

@Component({
    selector: "app-transcoding-profile-list",
    templateUrl: "./transcoding-profile-list.component.html"
})
export class TranscodingProfileListComponent implements OnInit, OnDestroy {
    refreshing = false;
    transcodingProfileName: string;
    selectedRows: TranscodingProfile[] = [];
    urls = Constants.urls;
    constants = Constants;

    tableColumnsSchema: TableSchema<TranscodingProfile>[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 280,
            visible: true,
            sticky: 1,

            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<TranscodingProfile>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: row => row.name,
            textValue: row => row.name,
            valueToExport: row => row.name
        },
        {
            header: this.translate.instant("VIDEO"),
            columnDef: "video",
            width: 280,
            visible: true,
            sticky: 2,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.transcodingProfilesService.getVideoColumnTextByRow(row),
                row => this.transcodingProfilesService.getVideoColumnTextByRow(row),
                () => true
            ),
            sortBy: row => row._frontData.videoOrder,
            textValue: row => row._frontData.videoOrder,
            valueToExport: row =>
                this.translate.instant(row.do_video ? "TRANSCODE" : row.keep_video ? "ORIGINAL" : "REMOVE")
        },
        {
            header: this.translate.instant("VIDEO_CODEC"),
            columnDef: "video_codec",
            width: 100,
            visible: true,
            sticky: 3,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.transcodingProfilesService.getVideoCodecColumnTextByRow(row),
                row => this.transcodingProfilesService.getVideoCodecColumnTextByRow(row),
                row => !!row.do_video
            ),
            sortBy: row => (row.do_video ? this.transcodingProfilesService.getVideoCodecColumnTextByRow(row) : "-"),
            textValue: row => (row.do_video ? this.transcodingProfilesService.getVideoCodecColumnTextByRow(row) : "-")
        },
        {
            header: this.translate.instant("AUDIO"),
            columnDef: "audio",
            width: 100,
            visible: true,
            sticky: 4,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.transcodingProfilesService.getAudioColumnTextByRow(row),
                row => this.transcodingProfilesService.getAudioColumnTextByRow(row),
                () => true
            ),
            sortBy: row => row._frontData.audioOrder,
            textValue: row => row._frontData.audioOrder,
            valueToExport: row => this.transcodingProfilesService.getAudioColumnTextByRow(row)
        },
        {
            header: this.translate.instant("AUDIO_CODEC"),
            columnDef: "audio_codec",
            width: 240,
            visible: true,
            sticky: 5,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.transcodingProfilesService.getAudioCodecColumnTextByRow(row),
                row => this.transcodingProfilesService.getAudioCodecColumnTextByRow(row),
                row => !!row.do_audio
            ),
            sortBy: row => this.transcodingProfilesService.getAudioCodecColumnTextByRow(row),
            textValue: row => this.transcodingProfilesService.getAudioCodecColumnTextByRow(row),
            valueToExport: row => this.transcodingProfilesService.getAudioCodecColumnTextByRow(row)
        },
        {
            header: this.translate.instant("ENCODING_PROFILE"),
            columnDef: "encoding_profile",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.transcodingProfilesService.getEncodingProfileColumnTextByRow(row),
                row => this.transcodingProfilesService.getEncodingProfileColumnTextByRow(row),
                row => !!this.transcodingProfilesService.getEncodingProfileColumnTextByRow(row)
            ),
            valueToExport: row => this.transcodingProfilesService.getEncodingProfileColumnTextByRow(row)
        },
        {
            header: this.translate.instant("RESOLUTION"),
            columnDef: "resolution",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.transcodingProfilesService.getResolutionColumnTextByRow(row),
                row => this.transcodingProfilesService.getResolutionColumnTextByRow(row),
                row => !!this.transcodingProfilesService.getResolutionColumnTextByRow(row)
            ),
            valueToExport: row => this.transcodingProfilesService.getResolutionColumnTextByRow(row)
        },
        {
            header: this.translate.instant("FPS"),
            columnDef: "fps",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => row.fps || this.translate.instant("ORIGINAL"),
                row => row.fps || this.translate.instant("ORIGINAL"),
                () => true
            ),
            valueToExport: row => row.fps || this.translate.instant("ORIGINAL")
        },
        {
            header: this.translate.instant("GOP"),
            columnDef: "gop",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => (row.gop ? row.gop.toString() : ""),
                row => (row.gop ? row.gop.toString() : ""),
                row => !!row.gop
            ),
            valueToExport: row => (row.gop ? row.gop.toString() : "")
        },
        {
            header: this.translate.instant("CLOSED_GOP"),
            columnDef: "closed_gop",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.translate.instant(row.gop_closed ? "YES" : "NO"),
                row => this.translate.instant(row.gop_closed ? "YES" : "NO"),
                () => true
            ),
            valueToExport: row => this.translate.instant(row.gop_closed ? "YES" : "NO")
        },
        {
            header: this.translate.instant("FIXED_GOP"),
            columnDef: "fixed_gop",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.translate.instant(row.gop_fixed ? "YES" : "NO"),
                row => this.translate.instant(row.gop_fixed ? "YES" : "NO"),
                () => true
            ),
            valueToExport: row => this.translate.instant(row.gop_fixed ? "YES" : "NO")
        },
        {
            header: this.translate.instant("AVERAGE_VIDEO_BITRATE_KBPS"),
            columnDef: "average_video_bitrate_kbps",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => row.bitrate_avg.toString(),
                row => row.bitrate_avg.toString(),
                () => true
            ),
            valueToExport: row => row.bitrate_avg.toString()
        },
        {
            header: this.translate.instant("MAX_VIDEO_BITRATE_KBPS"),
            columnDef: "max_video_bitrate_kbps",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => row.bitrate_max.toString(),
                row => row.bitrate_max.toString(),
                () => true
            ),
            valueToExport: row => row.bitrate_max.toString()
        },
        {
            header: this.translate.instant("PERFORMANCE"),
            columnDef: "performance",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.constants.videoPerformances[row.performance].name,
                row => this.constants.videoPerformances[row.performance].name,
                () => true
            ),
            valueToExport: row => this.constants.videoPerformances[row.performance].name
        },
        {
            header: this.translate.instant("B_FRAMES"),
            columnDef: "b_frames",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => row.b_frames.toString(),
                row => row.b_frames.toString(),
                () => true
            ),
            valueToExport: row => row.b_frames.toString()
        },
        {
            header: this.translate.instant("INTERLACED"),
            columnDef: "interlaced",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.translate.instant(row.interlaced ? "YES" : "NO"),
                row => this.translate.instant(row.interlaced ? "YES" : "NO"),
                () => true
            ),
            valueToExport: row => this.translate.instant(row.interlaced ? "YES" : "NO")
        },
        {
            header: this.translate.instant("REFERENCE_FRAMES"),
            columnDef: "reference_frames",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => row.ref_frames.toString(),
                row => row.ref_frames.toString(),
                () => true
            ),
            valueToExport: row => row.ref_frames.toString()
        },
        {
            header: this.translate.instant("HRD_BUFFER"),
            columnDef: "hrd_buffer",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => row.hdr_buff_length.toString(),
                row => row.hdr_buff_length.toString(),
                () => true
            ),
            valueToExport: row => row.hdr_buff_length.toString()
        },
        {
            header: this.translate.instant("CODEC"),
            columnDef: "codec",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => (row.video_codec === "h264" ? "H.264" : row.video_codec === "h265" ? "H.265" : ""),
                row => (row.video_codec === "h264" ? "H.264" : row.video_codec === "h265" ? "H.265" : ""),
                () => true
            ),
            valueToExport: row => (row.video_codec === "h264" ? "H.264" : row.video_codec === "h265" ? "H.265" : "")
        },
        {
            header: this.translate.instant("AUDIO_PROFILE"),
            columnDef: "audio_profile",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => this.constants.audioProfiles[row.audio_encoder_profile],
                row => this.constants.audioProfiles[row.audio_encoder_profile],
                () => true
            ),
            valueToExport: row => this.constants.audioProfiles[row.audio_encoder_profile]
        },
        {
            header: this.translate.instant("AUDIO_BITRATE"),
            columnDef: "audio_bitrate",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => row.audio_bitrate.toString(),
                row => row.audio_bitrate.toString(),
                () => true
            ),
            valueToExport: row => row.audio_bitrate.toString()
        },
        {
            header: this.translate.instant("AUDIO_SAMPLING_RATE"),
            columnDef: "audio_sampling_rate",
            width: 240,
            visible: false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<TranscodingProfile>>(
                row => row._frontData?.prettyAudioSampleRate?.name || this.translate.instant("ORIGINAL"),
                row => row._frontData?.prettyAudioSampleRate?.name || this.translate.instant("ORIGINAL"),
                () => true
            ),
            valueToExport: row => row._frontData?.prettyAudioSampleRate?.name || this.translate.instant("ORIGINAL")
        }
    ];

    private routeSubscription: Subscription;
    private transcodingProfilesSubscription: Subscription;
    private transcodingProfilesRefreshSubscription: Subscription;

    transcodingProfilesBX$ = new BehaviorSubject<TranscodingProfile[]>([]);

    constructor(
        private router: Router,
        private transcodingProfilesService: TranscodingProfilesService,
        private modalService: ModalService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        // Set Title
        this.titleService.setTitle("TRANSCODING_PROFILES", "");
        //
        this.routeSubscription = this.router.events
            .pipe(filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0))
            .subscribe((event: ActivationEnd) => {
                if (event.snapshot.params && event.snapshot.params.name) {
                    this.transcodingProfileName = event.snapshot.params.name;
                    this.updateSelectedProfile();
                } else {
                    this.transcodingProfileName = null;
                    this.selectedRows = [];
                }
            });
    }

    ngOnInit() {
        // local storage
        document.getElementById("left-container").style.flexBasis = localStorage.getItem("list-panel-width");

        this.transcodingProfilesSubscription = this.transcodingProfilesService.transcodingProfiles.subscribe(
            transcodingProfiles => {
                if (transcodingProfiles) {
                    this.transcodingProfilesBX$.next([...transcodingProfiles]);
                    if (this.transcodingProfileName) {
                        this.updateSelectedProfile();
                    }
                }
            }
        );

        // Start Auto Refresh
        this.startTranscodingProfilesRefresh();
    }

    ngOnDestroy() {
        this.routeSubscription.unsubscribe();
        this.transcodingProfilesSubscription.unsubscribe();
        this.stopTranscodingProfilesRefresh();
    }

    private updateSelectedProfile() {
        const profile = this.transcodingProfilesBX$
            .getValue()
            .find(profile => profile.name === this.transcodingProfileName);
        if (profile) this.selectedRows = [profile];
    }

    selectRow = (transcodingProfile: TranscodingProfile) => {
        this.router.navigate([Constants.urls.transformation.transcoding_profiles, transcodingProfile.name]);
    };

    async refresh() {
        this.refreshing = true;
        let transcodingProfile = null;
        const transcodingProfiles = this.transcodingProfilesService.refreshTranscodingProfiles(true).toPromise();
        if (this.transcodingProfileName) {
            transcodingProfile = this.transcodingProfilesService
                .refreshTranscodingProfile(this.transcodingProfileName, true)
                .toPromise();
        }
        await Promise.all([transcodingProfiles, transcodingProfile]);
        this.refreshing = false;
    }

    private async multiAction(action: string, func: (transcodingProfile: TranscodingProfile) => Promise<unknown>) {
        const result = await this.modalService.confirmMultiple(action, "TRANSCODING_PROFILE", this.selectedRows, func);
        if (result.actionTaken) {
            this.mixpanelService.sendEvent(
                this.translate.instant(action).toLowerCase() + " multiple transcoding profiles"
            );
            if (action === "DELETE") this.selectedRows = [];
        }
    }

    multiDelete() {
        this.multiAction("DELETE", async (transcodingProfile: TranscodingProfile) =>
            this.transcodingProfilesService.deleteTranscodingProfile(transcodingProfile)
        );
    }

    private startTranscodingProfilesRefresh() {
        this.transcodingProfilesRefreshSubscription = interval(60000).subscribe(() => {
            this.refresh();
        });
    }

    private stopTranscodingProfilesRefresh() {
        this.transcodingProfilesRefreshSubscription.unsubscribe();
    }
}
